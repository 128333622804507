
import { defineComponent, ref, onMounted, watch } from 'vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { PList } from '@/core/data/playList';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import moment from 'moment';

export default defineComponent({
	name: 'playlist-listing',
	components: {
		Datatable
	},
	setup() {
		const loading = ref(true);
		const paginationHeader = ref<any>({ TotalCount: 0 });
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});
		const checkedMedia = ref([]);
		const tableHeader = ref([
            {
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'name',
				sortable: true
			},
			{
				name: 'Content Type',
				key: 'contentType',
				sortable: true
			},
			{
				name: 'Sort Rule',
				key: 'sortRule',
				sortable: true
			},
			{
				name: 'matchValueToAllFilters',
				key: 'matchValueToAllFilters',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		const tableData = ref<Array<PList>>([]);
		const initMedia = ref<Array<PList>>([]);

		const getPlayListRules = () => {
			ApiService.post(`PlayListRule/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					paginationHeader.value = JSON.parse(headers['x-pagination']);

					tableData.value.splice(0, tableData.value.length, ...data);
					initMedia.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		watch(
			() => pagingFilteration.value.paging.pageSize,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getPlayListRules();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getPlayListRules();
			},
			{ deep: true }
		);

		const onItemsPerPageChange = itemsPerPage => {
			// console.log('itemsPerPage: ', itemsPerPage);
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			// console.log('current page: ', currentPage);
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('Playlist', []);
			getPlayListRules();
		});

		const deletePlayListRule = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const deleteData = id => {
			ApiService.delete(`PlayListRule/${id}`)
				.then(({ data }) => {
					// console.log('data', data);
					getPlayListRules();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const searchItems = () => {
			getPlayListRules();
		};

		return {
			moment,
			loading,
			tableData,
			tableHeader,
			getPlayListRules,
			searchItems,
			checkedMedia,
			deletePlayListRule,
			onCurrentChange,
			paginationHeader,
			pagingFilteration,
			onItemsPerPageChange
		};
	}
});
